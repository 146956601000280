$fontSize: 20px;
.cursor {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  mix-blend-mode: difference;
  isolation: isolate;
  z-index: 4;
  filter: blur(30px);
}
// #blur {
//   height: 100vh;
//   width: 100vw;
//   position: absolute;
//   backdrop-filter: blur(0px);
//   z-index: 2;
// }

.skills {
  display: grid;
  grid-template-columns: 1fr min-content;
  height: auto;
  z-index: 3;
  .skills__text {
    cursor: url("../../Assets/cursor1\ \(Custom\).png"), default;
    // cursor: pointer;
    color: white;
    .skills__text__container {
      padding-left: 64px;
      .skills__text__container__title {
        color: #ffff33;
        font-size: 48px;
        font-weight: bold;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
      }
      .skills__text__container__experience {
        color: #588b4e;
      }
      .skills__text__container__Main {
        padding-top: 5vh;
        .skills__text__container__Main__text {
          padding-bottom: 2vh;
          color: whitesmoke;
          font-size: $fontSize;
        }
        .skills__text__container__Main__langs {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          column-gap: 16px;
          color: #dbe80c;
          padding-bottom: 2vh;
          font-size: 18px;
          div {
            padding-bottom: 1vh;
          }
        }
      }
      .skills__text__container__languages {
        .skills__text__container__languages__title {
          padding-bottom: 2vh;
          color: whitesmoke;
          font-size: 20px;
        }
        .skills__text__container__languages__text {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          column-gap: 16px;
          font-size: $fontSize;
          color: rgb(243, 221, 23);
          div {
            // text-shadow: 1px 1px 0 #8d8d8d, 2px 2px 0 #000000, 3px 3px #000000,
            //   4px 4px 0 #ffffff;
          }
        }
        div {
          padding-bottom: 1vh;
        }
      }
    }
  }
  .skills__sphere {
    margin: 0;
    padding: 0;
    .skills__sphere__container {
      margin: 0;
      padding: 0;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .skills {
    .skills__text {
      .skills__text__container {
        padding-left: 16px;
        .skills__text__container__Main {
          padding-top: 5vh;
          .skills__text__container__Main__text {
            font-size: 16px;
          }
          .skills__text__container__Main__langs {
            font-size: 16px;
          }
        }
        .skills__text__container__languages {
          .skills__text__container__languages__title {
            font-size: 20px;
          }
          .skills__text__container__languages__text {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .skills {
    grid-template-rows: 1fr min-content !important;
    grid-template-columns: 1fr;
    grid-template-areas:
      "skillsText"
      "skillsSphere";
    .skills__text {
      color: white;
      grid-area: skillsText;
      .skills__text__container {
        padding-left: 0px;
        width: auto;
        // display: grid;
        // place-items: center;
        .skills__text__container__title {
          color: #ffff33;
          font-size: 48px;
          font-weight: bold;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        }
        .skills__text__container__experience {
          color: #588b4e;
        }
        .skills__text__container__Main {
          padding-top: 5vh;
          .skills__text__container__Main__text {
            padding-bottom: 2vh;
            color: whitesmoke;
            font-size: $fontSize;
          }
          .skills__text__container__Main__langs {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 16px;
            color: #dbe80c;
            padding-bottom: 2vh;
            font-size: 18px;
            div {
              padding-bottom: 1vh;
            }
          }
        }
        .skills__text__container__languages {
          .skills__text__container__languages__title {
            padding-bottom: 2vh;
            color: whitesmoke;
            font-size: 20px;
          }
          .skills__text__container__languages__text {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 16px;
            font-size: $fontSize;
            color: rgb(243, 221, 23);
            div {
              // text-shadow: 1px 1px 0 #8d8d8d, 2px 2px 0 #000000, 3px 3px #000000,
              //   4px 4px 0 #ffffff;
            }
          }
          div {
            padding-bottom: 1vh;
          }
        }
      }
    }
  }
}
