.cursor {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  mix-blend-mode: difference;
  isolation: isolate;
  z-index: 4;
  filter: blur(30px);
}

.skills {
  display: grid;
  grid-template-columns: 1fr min-content;
  height: auto;
  z-index: 3;
}
.skills .skills__text {
  cursor: url("../../Assets/cursor1 (Custom).png"), default;
  color: white;
}
.skills .skills__text .skills__text__container {
  padding-left: 64px;
}
.skills .skills__text .skills__text__container .skills__text__container__title {
  color: #ffff33;
  font-size: 48px;
  font-weight: bold;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.skills .skills__text .skills__text__container .skills__text__container__experience {
  color: #588b4e;
}
.skills .skills__text .skills__text__container .skills__text__container__Main {
  padding-top: 5vh;
}
.skills .skills__text .skills__text__container .skills__text__container__Main .skills__text__container__Main__text {
  padding-bottom: 2vh;
  color: whitesmoke;
  font-size: 20px;
}
.skills .skills__text .skills__text__container .skills__text__container__Main .skills__text__container__Main__langs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -moz-column-gap: 16px;
       column-gap: 16px;
  color: #dbe80c;
  padding-bottom: 2vh;
  font-size: 18px;
}
.skills .skills__text .skills__text__container .skills__text__container__Main .skills__text__container__Main__langs div {
  padding-bottom: 1vh;
}
.skills .skills__text .skills__text__container .skills__text__container__languages .skills__text__container__languages__title {
  padding-bottom: 2vh;
  color: whitesmoke;
  font-size: 20px;
}
.skills .skills__text .skills__text__container .skills__text__container__languages .skills__text__container__languages__text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -moz-column-gap: 16px;
       column-gap: 16px;
  font-size: 20px;
  color: rgb(243, 221, 23);
}
.skills .skills__text .skills__text__container .skills__text__container__languages div {
  padding-bottom: 1vh;
}
.skills .skills__sphere {
  margin: 0;
  padding: 0;
}
.skills .skills__sphere .skills__sphere__container {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 1100px) {
  .skills .skills__text .skills__text__container {
    padding-left: 16px;
  }
  .skills .skills__text .skills__text__container .skills__text__container__Main {
    padding-top: 5vh;
  }
  .skills .skills__text .skills__text__container .skills__text__container__Main .skills__text__container__Main__text {
    font-size: 16px;
  }
  .skills .skills__text .skills__text__container .skills__text__container__Main .skills__text__container__Main__langs {
    font-size: 16px;
  }
  .skills .skills__text .skills__text__container .skills__text__container__languages .skills__text__container__languages__title {
    font-size: 20px;
  }
  .skills .skills__text .skills__text__container .skills__text__container__languages .skills__text__container__languages__text {
    font-size: 16px;
  }
}
@media only screen and (max-width: 900px) {
  .skills {
    grid-template-rows: 1fr min-content !important;
    grid-template-columns: 1fr;
    grid-template-areas: "skillsText" "skillsSphere";
  }
  .skills .skills__text {
    color: white;
    grid-area: skillsText;
  }
  .skills .skills__text .skills__text__container {
    padding-left: 0px;
    width: auto;
  }
  .skills .skills__text .skills__text__container .skills__text__container__title {
    color: #ffff33;
    font-size: 48px;
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  }
  .skills .skills__text .skills__text__container .skills__text__container__experience {
    color: #588b4e;
  }
  .skills .skills__text .skills__text__container .skills__text__container__Main {
    padding-top: 5vh;
  }
  .skills .skills__text .skills__text__container .skills__text__container__Main .skills__text__container__Main__text {
    padding-bottom: 2vh;
    color: whitesmoke;
    font-size: 20px;
  }
  .skills .skills__text .skills__text__container .skills__text__container__Main .skills__text__container__Main__langs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -moz-column-gap: 16px;
         column-gap: 16px;
    color: #dbe80c;
    padding-bottom: 2vh;
    font-size: 18px;
  }
  .skills .skills__text .skills__text__container .skills__text__container__Main .skills__text__container__Main__langs div {
    padding-bottom: 1vh;
  }
  .skills .skills__text .skills__text__container .skills__text__container__languages .skills__text__container__languages__title {
    padding-bottom: 2vh;
    color: whitesmoke;
    font-size: 20px;
  }
  .skills .skills__text .skills__text__container .skills__text__container__languages .skills__text__container__languages__text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -moz-column-gap: 16px;
         column-gap: 16px;
    font-size: 20px;
    color: rgb(243, 221, 23);
  }
  .skills .skills__text .skills__text__container .skills__text__container__languages div {
    padding-bottom: 1vh;
  }
}/*# sourceMappingURL=index.css.map */