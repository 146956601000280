.SubmitButton {
  width: 200px;
  height: 50px;
  background-color: rgb(0, 238, 255);
  z-index: 3;
  cursor: pointer;

  .SubmitButton__textContainer {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    font-size: 20px;
    font-weight: 500;
    .SubmitButton__textContainer__txt {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      font-size: 20px;
      font-family: monospace;
      background-color: transparent;
      cursor: pointer;
    }
  }
  .SubmitButton__imgContainer {
    position: relative;
    top: 0;
    .SubmitButton__imgContainer__img {
      position: absolute;
      top: 1px;
      left: 150px;
      width: 48px;
      height: 48px;
      opacity: 0;
    }
  }
}
