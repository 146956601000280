.ContactButtonContainer {
  height: 100%;
  width: 100%;
  display: flex;
}
.ContactButtonContainer .ContactButton {
  width: 200px;
  height: 55px;
  font-size: 18px;
  font-family: monospace;
  position: relative;
  border: none;
  overflow: hidden;
  background-color: yellow;
}
.ContactButtonContainer .ContactButton :hover {
  cursor: pointer;
}
.ContactButtonContainer .ContactButton .ContactButton__txtContainer {
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
}
.ContactButtonContainer .ContactButton .ContactButton__txtContainer .ContactButton__txtContainer__txt {
  z-index: 3;
}
.ContactButtonContainer .ContactButton .ContactButton__span {
  background: rgb(131, 58, 180);
  background: radial-gradient(circle, rgb(131, 58, 180) 0%, rgb(253, 29, 29) 50%, rgb(252, 176, 69) 100%);
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}/*# sourceMappingURL=index.css.map */