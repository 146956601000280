.GmailCopy {
  font-size: 16px;
  display: grid;
  grid-template-columns: 1fr 32px;
  place-items: center;
  background-color: rgb(159, 235, 248);
  margin-left: auto;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 2px;
  z-index: 2 !important;
  &:hover {
    cursor: pointer;
  }
  .GmailCopy__mail {
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    z-index: 2;
  }
  .GmailCopy__button {
    height: 32px;
    width: 32px;
    display: grid;
    place-items: center;
    z-index: 2;
    .GmailCopy__button__img {
      width: 28px;
      height: 28px;
      z-index: 2;
    }
  }
}
