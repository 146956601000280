:root {
  --scale: 1;
}
@keyframes animatePositionTwo {
  0% {
    transform: translate(0%, 0%) rotate(30deg) skew(-20deg) scale(var(--scale), var(--scale));
  }
  100% {
    transform: translate(6px, 6px) rotate(30deg) skew(-20deg) scale(var(--scale), var(--scale));
  }
}
@keyframes animatePseudoBeforeTwo {
  0% {
    width: 18px;
    top: 9px;
    left: 210px;
    height: 100%;
  }
  100% {
    top: 6px;
    width: 12px;
    left: 213px;
  }
}
@keyframes animatePseudoAfterTwo {
  0% {
    bottom: -18px;
    left: 9px;
    height: 18px;
    width: 100%;
  }
  100% {
    bottom: -12px;
    left: 12px;
    height: 12px;
    width: 100%;
  }
}
.SocialButtonTwo {
  width: 210px;
  height: 65px;
  display: grid;
  place-items: center;
  background-color: white;
  color: black;
  transform: rotate(30deg) skew(-20deg) scale(var(--scale), var(--scale));
  box-shadow: -20px 20px 10px rgba(0, 0, 0, 0.5);
}
.SocialButtonTwo:hover {
  cursor: pointer;
}
.SocialButtonTwo:active {
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.2s;
  -webkit-animation-name: animatePositionTwo;
  -webkit-animation-fill-mode: fowards;
  -webkit-animation-direction: ease-in-out;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.2s;
  -moz-animation-name: animatePositionTwo;
  -moz-animation-fill-mode: fowards;
  -moz-animation-direction: ease-in-out;
  animation-delay: 0;
  animation-duration: 0.2s;
  animation-name: animatePositionTwo;
  animation-fill-mode: fowards;
  animation-direction: ease-in-out;
}
.SocialButtonTwo::before {
  content: "";
  position: absolute;
  top: 9px;
  left: 210px;
  height: 100%;
  width: 18px;
  background-color: rgb(128, 128, 128);
  transform: rotate(0deg) skewY(45deg);
}
.SocialButtonTwo:active::before {
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.2s;
  -webkit-animation-name: animatePseudoBeforeTwo;
  -webkit-animation-fill-mode: fowards;
  -webkit-animation-direction: ease-in-out;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.2s;
  -moz-animation-name: animatePseudoBeforeTwo;
  -moz-animation-fill-mode: fowards;
  -moz-animation-direction: ease-in-out;
  animation-delay: 0;
  animation-duration: 0.2s;
  animation-name: animatePseudoBeforeTwo;
  animation-fill-mode: fowards;
  animation-direction: ease-in-out;
}
.SocialButtonTwo:hover::before {
  background-color: rgb(51, 51, 51);
}
.SocialButtonTwo::after {
  content: "";
  position: absolute;
  bottom: -18px;
  left: 9px;
  height: 18px;
  width: 100%;
  background-color: #b1b1b1;
  transform: rotate(0deg) skewX(45deg);
}
.SocialButtonTwo:active::after {
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.2s;
  -webkit-animation-name: animatePseudoAfterTwo;
  -webkit-animation-fill-mode: fowards;
  -webkit-animation-direction: ease-in-out;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.2s;
  -moz-animation-name: animatePseudoAfterTwo;
  -moz-animation-fill-mode: fowards;
  -moz-animation-direction: ease-in-out;
  animation-delay: 0;
  animation-duration: 0.2s;
  animation-name: animatePseudoAfterTwo;
  animation-fill-mode: fowards;
  animation-direction: ease-in-out;
}
.SocialButtonTwo:hover::after {
  background-color: rgb(97, 97, 97);
}
.SocialButtonTwo .SocialButonTwo__button {
  border: none;
  outline: none;
  display: flex;
  flex-direction: row;
}
.SocialButtonTwo .SocialButonTwo__button .SocialButonTwo__button__iconContainer {
  width: 100%;
  display: grid;
  place-items: center;
}
.SocialButtonTwo .SocialButonTwo__button .SocialButonTwo__button__iconContainer .SocialButonTwo__button__iconContainer__icon {
  width: 48px;
  height: 48px;
}
.SocialButtonTwo .SocialButonTwo__button .SocialButonTwo__button__textContanier {
  width: 100%;
  display: grid;
  place-items: center;
}
.SocialButtonTwo .SocialButonTwo__button .SocialButonTwo__button__textContanier .SocialButonTwo__button__textContanier__text {
  padding-left: 5px;
  font-size: 20px;
  font-family: "Roboto Condensed", sans-serif;
}

@media screen and (max-width: 900px) {
  :root {
    --scale: 0.85;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --scale: 0.65;
  }
}
@media screen and (max-width: 420px) {
  :root {
    --scale: 0.6;
  }
}/*# sourceMappingURL=index.css.map */