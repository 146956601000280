$widthHeight: 18px;
$animation_time: 0.2s;
$rotation: -30deg;
$skew: 20deg;
$translate: 6px;
$scale: 1;
:root {
  --scale: #{1};
}
@mixin Keyframe($animation_name) {
  @-webkit-keyframes #{$animation_name} {
    @content;
  }

  @-moz-keyframes #{$animation_name} {
    @content;
  }

  @-o-keyframes #{$animation_name} {
    @content;
  }

  @keyframes #{$animation_name} {
    @content;
  }
}
@mixin animation(
  $delay,
  $duration,
  $animation,
  $direction: forward,
  $fillmode: fowards
) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: $fillmode;
  -webkit-animation-direction: $direction;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: $fillmode;
  -moz-animation-direction: $direction;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: $fillmode;
  animation-direction: $direction;
}
@include Keyframe(animatePosition) {
  0% {
    transform: translate(0%, 0%) rotate($rotation) skew($skew)
      scale(var(--scale), var(--scale));
  }
  100% {
    transform: translate(-$translate, $translate) rotate($rotation) skew($skew)
      scale(var(--scale), var(--scale));
  }
}
@include Keyframe(animatePseudoBefore) {
  0% {
    width: $widthHeight;
    top: $widthHeight/2;
    left: -$widthHeight;
    height: 100%;
  }
  100% {
    top: $widthHeight/2 - $translate/2;
    width: $widthHeight - $translate;
    left: -$widthHeight + $translate;
  }
}
@include Keyframe(animatePseudoAfter) {
  0% {
    bottom: -$widthHeight;
    left: -$widthHeight/2;
    height: $widthHeight;
    width: 100%;
  }
  100% {
    bottom: -$widthHeight + $translate;
    left: -$widthHeight/2 + $translate/2;
    height: $widthHeight - $translate;
    width: 100%;
  }
}
.SocialButton {
  width: 210px;
  height: 65px;
  display: grid;
  place-items: center;
  background-color: white;
  color: black;
  transform: rotate(-30deg) skew(20deg) scale(var(--scale), var(--scale));
  box-shadow: -20px 20px 10px rgba(0, 0, 0, 0.5);
  &:hover {
    cursor: pointer;
  }
  &:active {
    @include animation(0, $animation_time, animatePosition, ease-in-out);
  }
  &::before {
    content: "";
    position: absolute;
    top: $widthHeight/2;
    left: -$widthHeight;
    height: 100%;
    width: $widthHeight;
    background-color: rgb(128, 128, 128);
    transform: rotate(0deg) skewY(-45deg);
  }

  &:active::before {
    @include animation(0, $animation_time, animatePseudoBefore, ease-in-out);
  }
  &:hover::before {
    background-color: rgb(51, 51, 51);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -$widthHeight;
    left: -$widthHeight/2;
    height: $widthHeight;
    width: 100%;
    background-color: #b1b1b1;
    transform: rotate(0deg) skewX(-45deg);
  }
  &:active::after {
    @include animation(0, $animation_time, animatePseudoAfter, ease-in-out);
  }
  &:hover::after {
    background-color: rgb(97, 97, 97);
  }
  .SocialButon__button {
    border: none;
    outline: none;
    display: flex;
    flex-direction: row;
    .SocialButon__button__iconContainer {
      width: 100%;
      display: grid;
      place-items: center;
      .SocialButon__button__iconContainer__icon {
        width: 48px;
        height: 48px;
      }
    }
    .SocialButon__button__textContanier {
      width: 100%;
      display: grid;
      place-items: center;
      .SocialButon__button__textContanier__text {
        padding-left: 5px;
        font-size: 20px;
        font-family: "Roboto Condensed", sans-serif;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  :root {
    --scale: #{0.8};
  }
}
@media screen and (max-width: 500px) {
  :root {
    --scale: #{0.65};
  }
}
