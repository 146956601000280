.letterContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "hi" "imBimal" "web" "dev";
}
.letterContainer .letterContainer__hi {
  grid-area: hi;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto;
}
.letterContainer .letterContainer__hi div {
  margin: 3px;
  display: grid;
  place-items: end;
}
.letterContainer .letterContainer__imBimal {
  grid-area: imBimal;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto;
}
.letterContainer .letterContainer__imBimal div {
  margin-left: 3px;
  display: grid;
  place-items: end;
}
.letterContainer .letterContainer__imBimal .letterContainer__imBimal__m {
  margin-right: 16px;
}
.letterContainer .letterContainer__imBimal .letterContainer__imBimal__semicolon {
  display: grid;
  place-items: center !important;
  height: 70%;
}
.letterContainer .letterContainer__Web {
  grid-area: web;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto;
}
.letterContainer .letterContainer__Web div {
  margin: 3px;
  display: grid;
  place-items: end;
}
.letterContainer .letterContainer__dev {
  grid-area: dev;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto;
}
.letterContainer .letterContainer__dev div {
  margin: 3px;
  display: grid;
  place-items: end;
}/*# sourceMappingURL=index.css.map */