.Home__MetaBallContainer {
  overflow-x: hidden !important;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: -1;
}

.Home {
  z-index: 2;
  width: 100%;
  display: grid;
  grid-template-rows: 48px 1fr;
  grid-template-columns: 48px 1fr 1fr;
  grid-template-areas: "navContainer navContainer navContainer" ". textContainer contactButtonContainer";
}
.Home .Home__navContainer {
  grid-area: navContainer;
  width: 100%;
  height: 64px;
}
.Home .Home__textContainer {
  grid-area: textContainer;
  margin-top: 48px;
}
.Home .Home__contactButtonContainer {
  grid-area: contactButtonContainer;
  display: grid;
  justify-content: center;
  align-content: end;
}

.tags {
  color: rgb(73, 73, 73);
  font-style: italic;
  font-family: cursive;
  font-size: 20px;
}

@media screen and (max-width: 700px) {
  .Home {
    grid-template-rows: 48px 1fr 70px;
    grid-template-columns: 20px 1fr;
    grid-template-areas: "navContainer navContainer" ". textContainer " ". contactButtonContainer";
  }
  .Home .Home__contactButtonContainer {
    justify-content: end;
  }
}
@media screen and (max-width: 400px) {
  .Home {
    grid-template-rows: 48px 1fr 70px;
    grid-template-columns: 1fr;
    grid-template-areas: " navContainer" " textContainer " " contactButtonContainer";
  }
  .Home .Home__contactButtonContainer {
    justify-content: end;
  }
}/*# sourceMappingURL=Index.css.map */