$NavbarWidth: 300px;

.NavbarTwo {
  display: grid;
  width: $NavbarWidth;
  height: 48px;
  margin-left: calc(100% - #{$NavbarWidth});
  margin-top: 16px;
  background-color: cyan;
  position: fixed;
  top: 0;
  border-radius: 5px 0px 0px 5px;
  font-family: "Fredoka", sans-serif;
  z-index: 5;

  .NavbarTwo__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > div {
      width: 100px;
      height: 100%;
      text-align: center;
      display: grid;
      place-items: center;
      cursor: pointer;
    }
  }
  .NavbarTwo__indicator {
    width: 70px;
    height: 70px;
    background-color: cyan;
    border-radius: 50%;
    position: absolute;
    bottom: -30px;
    left: 10px;
    z-index: -1;
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      background-color: transparent;
      bottom: 19px;
      left: -8px;
      border-radius: 50%;
      box-shadow: 2px -2px cyan;
    }
    &::after {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      background-color: transparent;
      bottom: 19px;
      left: 68px;
      border-radius: 50%;
      box-shadow: -2px -2px cyan;
    }
  }
}
