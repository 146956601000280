.NavbarTwo {
  display: grid;
  width: 300px;
  height: 48px;
  margin-left: calc(100% - 300px);
  margin-top: 16px;
  background-color: cyan;
  position: fixed;
  top: 0;
  border-radius: 5px 0px 0px 5px;
  font-family: "Fredoka", sans-serif;
  z-index: 5;
}
.NavbarTwo .NavbarTwo__container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.NavbarTwo .NavbarTwo__container > div {
  width: 100px;
  height: 100%;
  text-align: center;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.NavbarTwo .NavbarTwo__indicator {
  width: 70px;
  height: 70px;
  background-color: cyan;
  border-radius: 50%;
  position: absolute;
  bottom: -30px;
  left: 10px;
  z-index: -1;
}
.NavbarTwo .NavbarTwo__indicator::before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  background-color: transparent;
  bottom: 19px;
  left: -8px;
  border-radius: 50%;
  box-shadow: 2px -2px cyan;
}
.NavbarTwo .NavbarTwo__indicator::after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  background-color: transparent;
  bottom: 19px;
  left: 68px;
  border-radius: 50%;
  box-shadow: -2px -2px cyan;
}/*# sourceMappingURL=index.css.map */