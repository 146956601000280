:root {
  --scale: 1;
}
@keyframes animatePosition {
  0% {
    transform: translate(0%, 0%) rotate(-30deg) skew(20deg) scale(var(--scale), var(--scale));
  }
  100% {
    transform: translate(-6px, 6px) rotate(-30deg) skew(20deg) scale(var(--scale), var(--scale));
  }
}
@keyframes animatePseudoBefore {
  0% {
    width: 18px;
    top: 9px;
    left: -18px;
    height: 100%;
  }
  100% {
    top: 6px;
    width: 12px;
    left: -12px;
  }
}
@keyframes animatePseudoAfter {
  0% {
    bottom: -18px;
    left: -9px;
    height: 18px;
    width: 100%;
  }
  100% {
    bottom: -12px;
    left: -6px;
    height: 12px;
    width: 100%;
  }
}
.SocialButton {
  width: 210px;
  height: 65px;
  display: grid;
  place-items: center;
  background-color: white;
  color: black;
  transform: rotate(-30deg) skew(20deg) scale(var(--scale), var(--scale));
  box-shadow: -20px 20px 10px rgba(0, 0, 0, 0.5);
}
.SocialButton:hover {
  cursor: pointer;
}
.SocialButton:active {
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.2s;
  -webkit-animation-name: animatePosition;
  -webkit-animation-fill-mode: fowards;
  -webkit-animation-direction: ease-in-out;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.2s;
  -moz-animation-name: animatePosition;
  -moz-animation-fill-mode: fowards;
  -moz-animation-direction: ease-in-out;
  animation-delay: 0;
  animation-duration: 0.2s;
  animation-name: animatePosition;
  animation-fill-mode: fowards;
  animation-direction: ease-in-out;
}
.SocialButton::before {
  content: "";
  position: absolute;
  top: 9px;
  left: -18px;
  height: 100%;
  width: 18px;
  background-color: rgb(128, 128, 128);
  transform: rotate(0deg) skewY(-45deg);
}
.SocialButton:active::before {
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.2s;
  -webkit-animation-name: animatePseudoBefore;
  -webkit-animation-fill-mode: fowards;
  -webkit-animation-direction: ease-in-out;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.2s;
  -moz-animation-name: animatePseudoBefore;
  -moz-animation-fill-mode: fowards;
  -moz-animation-direction: ease-in-out;
  animation-delay: 0;
  animation-duration: 0.2s;
  animation-name: animatePseudoBefore;
  animation-fill-mode: fowards;
  animation-direction: ease-in-out;
}
.SocialButton:hover::before {
  background-color: rgb(51, 51, 51);
}
.SocialButton::after {
  content: "";
  position: absolute;
  bottom: -18px;
  left: -9px;
  height: 18px;
  width: 100%;
  background-color: #b1b1b1;
  transform: rotate(0deg) skewX(-45deg);
}
.SocialButton:active::after {
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.2s;
  -webkit-animation-name: animatePseudoAfter;
  -webkit-animation-fill-mode: fowards;
  -webkit-animation-direction: ease-in-out;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.2s;
  -moz-animation-name: animatePseudoAfter;
  -moz-animation-fill-mode: fowards;
  -moz-animation-direction: ease-in-out;
  animation-delay: 0;
  animation-duration: 0.2s;
  animation-name: animatePseudoAfter;
  animation-fill-mode: fowards;
  animation-direction: ease-in-out;
}
.SocialButton:hover::after {
  background-color: rgb(97, 97, 97);
}
.SocialButton .SocialButon__button {
  border: none;
  outline: none;
  display: flex;
  flex-direction: row;
}
.SocialButton .SocialButon__button .SocialButon__button__iconContainer {
  width: 100%;
  display: grid;
  place-items: center;
}
.SocialButton .SocialButon__button .SocialButon__button__iconContainer .SocialButon__button__iconContainer__icon {
  width: 48px;
  height: 48px;
}
.SocialButton .SocialButon__button .SocialButon__button__textContanier {
  width: 100%;
  display: grid;
  place-items: center;
}
.SocialButton .SocialButon__button .SocialButon__button__textContanier .SocialButon__button__textContanier__text {
  padding-left: 5px;
  font-size: 20px;
  font-family: "Roboto Condensed", sans-serif;
}

@media screen and (max-width: 900px) {
  :root {
    --scale: 0.8;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --scale: 0.65;
  }
}/*# sourceMappingURL=index.css.map */